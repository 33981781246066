export default {
  appTitle: process.env.VUE_APP_TITLE,
  appShortTitle: process.env.VUE_APP_SHORT_TITLE,
  appDomain: process.env.VUE_APP_DOMAIN,
  cfGenerateGadz: process.env.VUE_APP_CF_GENERATE_GADZ,
  cfDownloadGadz: process.env.VUE_APP_CF_DOWNLOAD_GADZ,
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  adzIsSelected: false,
  platforms: null,
  commentsToggle: false,
  commentingAdz: null,
  resolvedToggle: false,
}
