<template>
  <header class="navbar" :class="{ offline: !networkOnLine }">
    <router-link to="/clients">
      <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />
    </router-link>

    <div class="right">
      <a
        v-if="accountMembership && accountMembership.id === 'FREE'"
        href="https://showadz.io/"
        target="_blank"
        rel="noopener noreferrer"
        class="upgrade-button"
      >
        <p>Upgrade</p>
      </a>

      <div v-if="account && account[0] && user && user !== undefined" class="user-profile-link" @click="showAccountOptions">
        <img v-if="isUserLoggedIn && networkOnLine && account[0].profileImg" class="account-picture" :src="account[0].profileImg" alt="Avatar" />
        <span v-else-if="isUserLoggedIn && networkOnLine && account[0]" :class="['account-empty-image-letter']">{{
          account[0].businessName[0].toUpperCase()
        }}</span>

        <img alt="Arrow down" src="@/assets/img/profile-arrow-down.svg" />
      </div>
    </div>

    <div v-if="account && account[0] && user && user !== undefined" class="account-options" :class="{ show: isActive }" @mouseleave="mouseLeave">
      <div v-if="account" class="profile">
        <img v-if="isUserLoggedIn && networkOnLine && account[0].profileImg" class="user-picture can-hide" :src="account[0].profileImg" alt="Avatar" />
        <span v-else :class="['account-empty-image-letter']">{{ account[0].businessName[0].toUpperCase() }}</span>
        <div class="account-infos">
          <p class="account-name">{{ account[0].businessName }}</p>
          <p class="account-user">{{ user.displayName }}</p>
        </div>
      </div>
      <router-link v-if="account && account[0].usersRoles[user.id].role === 'admin'" to="/manage-users" class="options">Manage users</router-link>
      <router-link to="/contact-us" class="options">Help</router-link>
      <a v-if="isUserLoggedIn && networkOnLine" class="options nav-item logout-item" @click="logout">Logout</a>
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
  data: function () {
    return {
      isActive: false,
    }
  },

  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'accountMembership']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
  },

  methods: {
    async logout() {
      await firebase.auth().signOut()
    },

    showAccountOptions() {
      if (this.isActive) {
        this.isActive = false
      } else {
        this.isActive = true
      }
    },

    mouseLeave: function () {
      if (this.isActive) {
        this.isActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-google,
.page-share-link-google {
  .navbar {
    display: none;
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  background-color: #fff;

  .logo {
    width: 145px;
    display: block;
  }

  .right {
    display: flex;
    align-items: center;

    .upgrade-button {
      font-size: 13px;
      color: #fff;
      background: linear-gradient(to right, #2bb0c5, #6bd8ba);
      font-weight: 600;
      border-radius: 3px;
      padding: 10px 15px;
      margin-right: 15px;
    }
  }

  .search-bar {
    width: 40vw;
    input {
      width: 100%;
      height: 45px;
      padding: 10px 15px;

      font-size: 14px;
      color: $dark-grey;
      font-weight: 600;
      background-color: $grey;
      background-image: url(../../assets/img/search-icon.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) center;
      border: 0;
      border-radius: 3px;
    }
  }

  .user-profile-link {
    display: flex;
    align-items: center;
    cursor: pointer;

    .account-picture {
      width: 30px;
      border-radius: 15px;
      margin-right: 8px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: $dark-grey;
      padding-right: 8px;
    }
  }
}

.account-options {
  position: absolute;
  top: 60px;
  right: 30px;
  width: 250px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e0e2e6;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  z-index: -1;
  visibility: hidden;

  font-size: 14px;
  color: $dark-grey;

  transition: all 0.3s ease;

  &.show {
    z-index: 1000;
    top: 70px;
    opacity: 1;
    visibility: visible;
  }

  .profile {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e0e2e6;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 10px;
    }

    .account-infos {
      .account-name {
        font-weight: 600;
        font-size: 14px;
      }
      .account-user {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .options {
    width: 100%;
    display: block;
    padding: 15px;
    color: $dark-grey;
    background-color: #fff;
    transition: all 0.2s ease;

    &:hover {
      background-color: $grey;
    }

    &.coming-soon {
      background-color: $grey;
      opacity: 0.35;
    }
  }

  .logout-item {
    cursor: pointer;
  }
}
</style>
