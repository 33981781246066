<template>
  <div class="page-wrapper">
    <div class="left">
      <div class="loginWrap">
        <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />

        <h1 class="big-title">Login</h1>

        <form action="" @submit="login">
          <input v-model="email" type="text" placeholder="Email" name="email" required />
          <input v-model="password" type="password" placeholder="Password" name="password" required />

          <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
            <div class="bt-text">
              <p>Login</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
            <p class="loader">
              &nbsp;
              <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
                <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                  <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                  </path>
                </g>
              </svg>
              &nbsp;
            </p>
          </button>
        </form>

        <p class="regular-text">Forgot your password? <a href="/help">Contact us</a></p>
        <p class="regular-text">Don’t have an Account? <a href="/create-account">Register now</a></p>

        <!-- <p class="or">OR</p> -->

        <!-- Loader -->
        <!-- <div v-show="user === undefined" data-test="loader">
          Authenticating...
        </div> -->

        <!-- Offline instruction -->
        <div v-show="!networkOnLine" data-test="offline-instruction">Please check your connection, login feature is not available offline.</div>

        <!-- <p v-if="loginError">{{ loginError }}</p> -->

        <!-- <section
          v-show="networkOnLine"
          id="firebaseui-auth-container"
        ></section> -->
      </div>
    </div>

    <div class="right">
      <div class="data">
        <h1>Create, preview and share all your Facebook and Google ads easily!</h1>

        <!-- <div class="bt">
          <p>Demandez vos accès</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div> -->
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
import { auth } from '@/firebase/init'

import { doAlert } from '@/misc/helpers'

export default {
  data: () => ({ isLoading: true, loginError: null, email: '', password: '' }),
  head: function () {
    return {
      title: {
        inner: 'Login',
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc',
        },
      ],
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle']),
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.isLoading = true
          const redirectUrl = isNil(this.$route.query.redirectUrl) ? '/clients' : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        } else {
          setTimeout(() => {
            this.isLoading = false
          }, 2555)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async login(e) {
      e.preventDefault()

      this.isLoading = true

      try {
        await auth.signInWithEmailAndPassword(this.email, this.password)
        // console.log({ login })
      } catch (error) {
        this.loginError = error
        doAlert({
          type: 'error',
          title: error,
        })
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/typography.scss';

.page-wrapper {
  display: flex;
}

.left {
  position: relative;
  width: 35vw;
  min-width: 450px;
  height: 100vh;
  padding: 0 40px;
  display: flex;
  align-items: center;

  .logo {
    width: 250px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .loginWrap {
    width: 100%;
    padding: 0 1vw;
    text-align: center;
  }

  h1 {
    margin-bottom: 30px;
  }

  input[type='text'] {
    margin-bottom: 10px;
  }

  input[type='password'] {
    margin-bottom: 10px;
  }

  .regular-text {
    margin-top: 30px;

    a {
      color: $purple-blue;
    }
  }

  .bt {
    width: 100%;

    &:hover {
      padding: 15px 25px 15px 25px;
    }
  }

  .or {
    margin: 15px 0;
    font-weight: 300;
    font-size: 14px;
  }
}

.right {
  position: relative;
  width: 65vw;
  height: 100vh;
  background-image: url('../assets/img/home-background.jpg');
  background-size: cover;

  .overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #1f2532;
    opacity: 0.9;
    z-index: 1;
  }

  .data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;

    width: 100%;
    max-width: 700px;

    h1 {
      color: #fff;
      font-weight: 300;
      text-align: center;
      font-size: 40px;
      margin-bottom: 25px;
    }

    .bt {
      display: inline-block;
      max-width: 250px;
      text-align: center;
    }
  }
}
</style>
