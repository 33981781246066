import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import { isNil } from 'lodash'
import Home from '@/views/Home'
import ConfirmInvite from '@/views/ConfirmInvite'
import CheckLogin from '@/views/CheckLogin'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /**
     * ===========================
     * Public Routes
     * ===========================
     */
    {
      path: '/',
      name: 'default',
      component: Home,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/invite-users/create/:inviteId',
      name: 'confirminvite',
      component: ConfirmInvite,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import(/* webpackChunkName: "client-chunk-privacy" */ '@/views/PrivacyPolicy.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfService',
      component: () => import(/* webpackChunkName: "client-chunk-terms" */ '@/views/TermsOfService.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/create-account',
      name: 'CreateAccount',
      component: () => import(/* webpackChunkName: "client-create-account" */ '@/views/CreateAccount.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/:clientId/campaign/:campaignId/adz-stories/:adzId/view',
      name: 'Stories',
      component: () => import(/* webpackChunkName: "client-chunk-terms" */ '@/views/auth/Stories.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/payment',
      name: 'Payment',
      component: () => import(/* webpackChunkName: "client-chunk-terms" */ '@/views/Payment.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/share-link/:shareLinkId',
      name: 'share-link-dynamic',
      component: () => import(/* webpackChunkName: "client-chunk-share-link" */ '@/views/ShareLinkView.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/share-link-google/:shareLinkId',
      name: 'share-link-google-dynamic',
      component: () => import(/* webpackChunkName: "client-chunk-share-link-google" */ '@/views/auth/Google.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/share-link',
      name: 'share-link',
      component: () => import(/* webpackChunkName: "client-chunk-share-link" */ '@/views/ShareLinkView.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/share-link-google',
      name: 'share-link-google',
      component: () => import(/* webpackChunkName: "client-chunk-share-link-google" */ '@/views/auth/Google.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/404',
      name: '404',
      props: true,
      component: () => import(/* webpackChunkName: "client-chunk-404" */ '@/views/404.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      props: true,
      component: () => import(/* webpackChunkName: "client-chunk-contactUs" */ '@/views/ContactUs.vue'),
      meta: {
        authNotRequired: true,
      },
    },

    /**
     * ===========================
     * Auth Layer protected Routes
     * ===========================
     */
    {
      path: '/clients',
      name: 'Clients',
      component: () => import(/* webpackChunkName: "client-chunk-clients" */ '@/views/auth/Clients.vue'),
    },
    {
      path: '/manage-users',
      name: 'ManageUsers',
      component: () => import(/* webpackChunkName: "client-chunk-manageusers" */ '@/views/auth/ManageUsers.vue'),
    },
    {
      path: '/invite-users',
      name: 'InviteUsers',
      component: () => import(/* webpackChunkName: "client-chunk-invite-users" */ '@/views/auth/InviteUsers.vue'),
    },
    {
      path: '/invite-users/update/:userId',
      name: 'UpdateUsers',
      component: () => import(/* webpackChunkName: "client-chunk-invite-edit-users" */ '@/views/auth/InviteUsers.vue'),
    },
    {
      path: '/create-client',
      name: 'CreateClient',
      component: () => import(/* webpackChunkName: "client-chunk-create-client" */ '@/views/auth/CreateClient.vue'),
    },
    {
      path: '/:clientId/edit-client',
      name: 'EditClient',
      component: () => import(/* webpackChunkName: "client-chunk-edit-client" */ '@/views/auth/CreateClient.vue'),
    },
    {
      path: '/:clientId/campaigns-list',
      name: 'CampaignsList',
      component: () => import(/* webpackChunkName: "client-chunk-campaigns-list" */ '@/views/auth/CampaignsList.vue'),
    },
    {
      path: '/:clientId/campaign/create-campaign',
      name: 'CreateCampaign',
      component: () => import(/* webpackChunkName: "client-chunk-add-platform" */ '@/views/auth/CreateCampaign.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/create-campaign',
      name: 'EditCampaign',
      component: () => import(/* webpackChunkName: "client-chunk-add-edit-platform" */ '@/views/auth/CreateCampaign.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/add-adz',
      name: 'AddFacebookAds',
      component: () => import(/* webpackChunkName: "client-chunk-add-facebook-ads" */ '@/views/auth/AddFacebookAds.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/adz/:adzId/create',
      name: 'CreateFacebookAds',
      component: () => import(/* webpackChunkName: "client-chunk-create-facebook-ads" */ '@/views/auth/CreateFacebookAds.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/adz-stories/:adzId/create',
      name: 'CreateFacebookStories',
      component: () => import(/* webpackChunkName: "client-chunk-create-facebook-stories" */ '@/views/auth/CreateFacebookStories.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/adz-multi/:adzId/create',
      name: 'CreateFacebookCarrousel',
      component: () => import(/* webpackChunkName: "client-chunk-create-facebook-c-ads" */ '@/views/auth/CreateFacebookCarrousel.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/adz-list',
      name: 'Campaign',
      component: () => import(/* webpackChunkName: "client-chunk-campaign" */ '@/views/auth/CampaignContent.vue'),
    },
    {
      path: '/:clientId/:campaignId/:adzId/preview',
      name: 'Facebook',
      component: () => import(/* webpackChunkName: "client-chunk-facebook" */ '@/views/auth/Facebook.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/preview/all',
      name: 'facebook-preview-all',
      component: () => import(/* webpackChunkName: "client-chunk-facebook-preview" */ '@/views/auth/Facebook.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/creategoogle',
      name: 'CreateGoogle',
      component: () => import(/* webpackChunkName: "client-chunk-create-google" */ '@/views/auth/CreateGoogle.vue'),
    },
    {
      path: '/:clientId/campaign/:campaignId/google',
      name: 'google',
      component: () => import(/* webpackChunkName: "client-chunk-preview-google" */ '@/views/auth/Google.vue'),
      meta: {
        authNotRequired: true,
      },
    },

    /**
     * ===========================
     * Auth Layer ADMIN protected Routes
     * ===========================
     */
    {
      path: '/admin/account/create',
      name: 'AdminCreateAccount',
      component: () => import(/* webpackChunkName: "client-chunk-admin-create-account" */ '@/views/admin/CreateAccount.admin.vue'),
    },
    {
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: () => import(/* webpackChunkName: "client-chunk-admin-dashboard" */ '@/views/admin/Dashboard.admin.vue'),
    },
    {
      path: '/admin/view-account/:accountId',
      name: 'AdminViewAccount',
      component: () => import(/* webpackChunkName: "client-chunk-admin-view-account" */ '@/views/admin/ViewAccount.admin.vue'),
    },
    {
      path: '/admin/view-account/:accountId/view-adz/:clientId',
      name: 'AdminViewAdz',
      component: () => import(/* webpackChunkName: "client-chunk-admin-view-adz" */ '@/views/admin/ViewAdz.admin.vue'),
    },

    /**
     * ===========================
     * Default Fallback
     * ===========================
     */
    { path: '*', redirect: '/404' },
  ],
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  if (to.path.includes('/share-link')) {
    next()
  }

  const requiresAuth = !(to.meta && to.meta.authNotRequired)

  if (requiresAuth && isNil(store.state.authentication.user)) {
    const path = store.state.authentication.user === null ? '/' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }

  next()
})

router.afterEach(() => {
  if (document.querySelector('.swal2-container.swal2-top-end')) {
    setTimeout(() => {
      document.querySelector('.swal2-container.swal2-top-end').innerHTML = ''
    }, 1235)
  }
})

export default router
