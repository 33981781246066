<template>
  <div
    class="custom-swal-share custom-swal-container custom-swal-center custom-swal"
    :class="{ 'custom-swal-shown': showCustomSwal }"
    @click.prevent="closeCustomSwal()"
  >
    <div
      aria-labelledby="custom-swal-title"
      aria-describedby="custom-swal-content"
      class="custom-swal-popup custom-swal-modal custom-swal-show"
      tabindex="-1"
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style="display: flex"
      @click.prevent.stop
    >
      <div class="custom-swal-header">
        <div class="custom-swal-icon custom-swal-warning share-state custom-swal-animate-warning-icon" style="display: flex"></div>
        <h2 id="custom-swal-title" class="custom-swal-title" style="display: flex">Share Link</h2>
        <button type="button" class="custom-swal-close" aria-label="Close this dialog" style="display: flex" @click.prevent="closeCustomSwal()">×</button>
      </div>
      <div class="custom-swal-btns">
        <div v-clipboard:copy="generatedLink" v-clipboard:success="onCopy" class="custom-swal-link-sharable" @click.prevent="closeCustomSwal()">
          <p class="link">{{ generatedLink }}</p>
          <div class="copy"><p>Copy</p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doAlert } from '@/misc/helpers'

export default {
  props: {
    showCustomSwal: {
      type: Boolean,
      default: false,
    },
    closeCustomSwal: {
      type: Function,
      required: true,
    },
    generatedLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    onCopy() {
      doAlert({ title: `Link copied and ready to be shared`, type: 'success' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/customSwalPopup.scss';

.custom-swal-link-sharable {
  background: #eee;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  .link {
    width: 75%;
    padding: 15px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    color: $dark-grey;
  }
  .copy {
    width: 25%;
    padding: 15px 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: linear-gradient(to right, #2e7df2, #8a23f1);

    p {
      color: #fff;
      font-weight: 600;
    }
  }
}

.custom-swal-share {
  .custom-swal-title {
    margin: 0 !important;
  }
  .bt {
    display: inline-block;
    margin: 0 auto;
  }
}
</style>
