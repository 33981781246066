<template>
  <div class="custom-swal-container custom-swal-center custom-swal" :class="{ 'custom-swal-shown': showCustomSwal }" @click.prevent="closeCustomSwal()">
    <div
      aria-labelledby="custom-swal-title"
      aria-describedby="custom-swal-content"
      class="custom-swal-popup custom-swal-modal custom-swal-show"
      tabindex="-1"
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style="display: flex"
      @click.prevent.stop
    >
      <div class="custom-swal-header">
        <div class="custom-swal-icon custom-swal-warning upgrade-state custom-swal-animate-warning-icon" style="display: flex"></div>
        <h2 id="custom-swal-title" class="custom-swal-title" style="display: flex">You have reach your account limit</h2>
        <button type="button" class="custom-swal-close" aria-label="Close this dialog" style="display: flex" @click.prevent="closeCustomSwal()">×</button>
      </div>
      <div class="custom-swal-content">
        <div id="custom-swal-content" style="display: block">Don't worry, upgrade your plan and<br />enjoy the full experience!</div>
      </div>
      <a href="https://showadz.io/" target="_blank" rel="noopener noreferrer" class="custom-swal-btns">
        <div class="bt smaller green">
          <p>Upgrade now</p>
          <img alt="Plus" class="bt-img bt-arrow" src="@/assets/img/arrow-right.svg" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCustomSwal: {
      type: Boolean,
      default: false,
    },
    closeCustomSwal: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/customSwalPopup.scss';
</style>
