import Vue from 'vue'

import store from '@/store'

import UsersDB from '@/firebase/users-db'
import AccountsDB from '@/firebase/accounts-db'
import InvitesDB from '@/firebase/invites-db'

/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async (firebaseAuthUser) => {
  const providerData = firebaseAuthUser.providerData[0]
  const userDb = new UsersDB()
  const invitesDBRef = new InvitesDB()

  // If the user is from an Invite...
  if (store.state.authentication.activeInvite) {
    const user = {
      displayName: store.state.authentication.activeInvite.displayName,
      photoURL: '',
      email: store.state.authentication.activeInvite.email,
    }

    const accountId = store.state.authentication.activeInvite.account.id
    const newUser = await userDb.create(user, firebaseAuthUser.uid)

    const accountsDbRef = new AccountsDB()
    const account = await accountsDbRef.read(accountId)

    const accountUsersList = account.usersList
    const accountUsersRoles = account.usersRoles

    accountUsersList.push(newUser.id)
    accountUsersRoles[newUser.id] = {
      role: store.state.authentication.activeInvite.role,
      clients: store.state.authentication.activeInvite.clients,
    }

    const updatedAccount = { ...account }

    await accountsDbRef.update(updatedAccount)
    await invitesDBRef.delete(store.state.authentication.activeInvite.id)

    store.commit('authentication/setActiveInvite', null)

    return newUser
  } else {
    // Create a Fresh / New User
    // And generate a new account for him
    const { displayName, photoURL, email } = providerData

    const user = {
      displayName,
      photoURL,
      email,
      selfCreated: false,
    }

    if (localStorage.getItem('account.creation.user.displayName')) {
      user.displayName = localStorage.getItem('account.creation.user.displayName')
    }

    if (localStorage.getItem('account.creation.user.selfCreated')) {
      user.selfCreated = localStorage.getItem('account.creation.user.selfCreated')

      const accountsDb = new AccountsDB()
      const userId = firebaseAuthUser.uid
      const businessName = localStorage.getItem('account.creation.user.businessName')
      const membership = localStorage.getItem('account.creation.user.membership')
      const usersRoles = {}
      usersRoles[userId] = { role: 'admin' }
      const account = {
        owner: userId,
        businessName,
        profileImg: '',
        isActive: true,
        isCompleted: false,
        usersList: [userId],
        usersRoles,
        membership,
      }
      await accountsDb.create(account)
    }

    localStorage.removeItem('account.creation.user.displayName')
    localStorage.removeItem('account.creation.user.membership')
    localStorage.removeItem('account.creation.user.selfCreated')
    localStorage.removeItem('account.creation.user.businessName')

    return await userDb.create(user, firebaseAuthUser.uid)
  }
}

/**
 * Create new account for the newly created user
 */
// eslint-disable-next-line
export const createNewUserAccount = async (userId) => {
  const accountsDbRef = new AccountsDB()
  const account = {
    businessName: userId,
    owner: userId,
    usersList: [userId],
    usersRoles: {},
    isActive: true,
    isCompleted: false,
    profileImg: '',
  }
  account.usersRoles[userId] = { role: 'admin' }
  return await accountsDbRef.create(account, userId)
}

export const getPercentFromZone = (e, target) => {
  const zone = target.getBoundingClientRect()
  const x = e.clientX - zone.left //x position within the element.
  const y = e.clientY - zone.top //y position within the element.
  const width = zone.width
  const height = zone.height
  const percentX = (x / width) * 100
  const percentY = (y / height) * 100

  return { percentX, percentY }
}

export const positionCommentBox = (e, usePosX = null, usePosY = null) => {
  console.log('positionCommentBox', usePosX, usePosY, e.clientX, e.clientY)
  let posX = e.clientX
  let posY = e.clientY
  if (usePosX && usePosY) {
    posX = usePosX
    posY = usePosY
  }
  ;[].slice.call(document.querySelectorAll('.comment-thread')).forEach(function (box) {
    box.setAttribute('style', `top:${posY + document.documentElement.scrollTop}px;left:${posX}px;`)
  })
}

export const resetCommentsOnAdz = (adz) => {
  adz.headerCommentMarkers = []
  adz.footerCommentMarkers = []
  adz.markerCurrentIndex = 1
  if (adz.content && adz.content.commentMarkers && adz.content.commentMarkers.length) {
    adz.content.commentMarkers = []
  }
  if (adz.contentSlider && adz.contentSlider.length) {
    adz.contentSlider.forEach(function (slide) {
      slide.commentMarkers = []
    })
  }
  return adz
}

export const applyDuplicateIdRefs = (adz) => {
  if (adz.content && adz.content.imageRef && !adz.content.imageRefFromId) {
    adz.content.imageRefFromId = adz.id
  }
  if (adz.content && adz.content.videoRef && !adz.content.videoRefFromId) {
    adz.content.videoRefFromId = adz.id
  }
  if (adz.contentSlider && adz.contentSlider.length) {
    adz.contentSlider.forEach(function (slide) {
      if (slide.imageRef && !slide.imageRefFromId) {
        slide.imageRefFromId = adz.id
      }
      if (slide.videoRef && !slide.videoRefFromId) {
        slide.videoRefFromId = adz.id
      }
    })
  }
  return adz
}

/**
 * Global doAlert() toaster message using Sweetalert2
 * title
 * type
 */
export const doAlert = ({ title, type }) => {
  if (type === 'error') {
    Vue.swal({
      title,
      icon: type,
    })
    return
  }
  Vue.swal({
    title,
    icon: type,
    toast: true,
    position: 'top-end',
    timer: 1500,
    timerProgressBar: false,
    showConfirmButton: false,
  })
}

export const slugify = (text) => {
  // console.log(context, text)
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ) // Trim - from end of text
}

export const detectAdBlock = () => {
  const resolve = !(document.querySelector('#checkAdBlocker').clientHeight >= 50)
  document.querySelector('#checkAdBlocker').innerHTML = ''
  return resolve
}
