import AccountsDB from '@/firebase/accounts-db'
import AccountclientsDB from '@/firebase/account-clients-db'
import MembershipDB from '@/firebase/membership-db'
import AccountClientCampaignsDB from '@/firebase/account-client-campaigns-db'
import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'

// import { createNewUserAccount } from '@/misc/helpers'

export default {
  /**
   * Fetch or create account of current loggedin user
   */
  getUserAccount: async ({ rootState, commit }) => {
    const accountsDbRef = new AccountsDB()
    const account = await accountsDbRef.find([['usersList', 'array-contains', rootState.authentication.user.id]])
    // console.log({ account })

    // This is DEPRECATED
    // We now give a 404/help page if the user does not have any account
    if (!account || !account.length) {
      // console.log('User does not have any account.')
      if (!window.location.href.includes('contact-us')) {
        window.location.href = '/contact-us'
      }
      return
      // account = await createNewUserAccount(rootState.authentication.user.id)
    }

    commit('setAccount', account)

    const accountMembership = account[0].membership ? account[0].membership : 'LEGACY'
    const membershipDb = new MembershipDB()
    const membershipConfig = await membershipDb.read(accountMembership)
    commit('setMembership', membershipConfig)
  },

  getUserClients: async ({ rootState, commit }) => {
    if (!rootState.users || !rootState.users.account) {
      return []
    }

    const accountClientsDbRef = new AccountclientsDB(rootState.users.account[0].id)
    let accountClients = await accountClientsDbRef.readAll(null, false)

    // console.log({accountClients}, rootState.users.account[0].usersRoles[rootState.authentication.user.id].role)

    if (rootState.users.account[0].usersRoles[rootState.authentication.user.id].role !== 'admin') {
      const clients = rootState.users.account[0].usersRoles[rootState.authentication.user.id].clients
        ? rootState.users.account[0].usersRoles[rootState.authentication.user.id].clients
        : []
      // console.log(clients)
      accountClients = accountClients.filter((i) => clients.includes(i.id))
    }

    // console.log({accountClients})

    commit('setClients', accountClients)
  },

  getUserClientCampaigns: async ({ rootState, commit }, clientId) => {
    const accountClientCampaignsDbRef = new AccountClientCampaignsDB(rootState.users.account[0].id, clientId)
    const campaigns = await accountClientCampaignsDbRef.readAll(null, false)
    commit('setCampaigns', campaigns)
  },

  selectAccount: async ({ commit }, accountId) => {
    // console.log('selectAccount', accountId)

    if (!accountId) {
      commit('setAccount', null)
      return
    }

    const accountsDbRef = new AccountsDB()
    const account = await accountsDbRef.read(accountId)

    commit('setAccount', [account])

    return true
  },

  selectClient: async ({ rootState, commit }, clientId, fromAccount = null) => {
    // console.log('selectClient', clientId)

    if (!clientId) {
      commit('setClient', null)
      return
    }

    const accountId = fromAccount ? fromAccount : rootState.users.account[0].id

    const accountClientsDbRef = new AccountclientsDB(accountId)
    const client = await accountClientsDbRef.read(clientId)

    commit('setClient', client)
  },

  selectCampaign: async ({ rootState, commit }, { clientId, campaignId, accountId }) => {
    if (!campaignId) {
      commit('setCampaign', null)
      return
    }

    const accountIdRef = accountId ? accountId : rootState.users.account[0].id

    const accountClientCampaignsDbRef = new AccountClientCampaignsDB(accountIdRef, clientId)
    const campaign = await accountClientCampaignsDbRef.read(campaignId)

    commit('setCampaign', campaign)
  },

  selectAdz: async ({ rootState, commit }, { clientId, campaignId, adzId }) => {
    if (!adzId) {
      commit('setAdz', null)
      return
    }

    const accountClientCampaignAdzDb = new AccountClientCampaignAdzDB(rootState.users.account[0].id, clientId, campaignId)
    const adz = await accountClientCampaignAdzDb.read(adzId)
    commit('setAdz', adz)
  },

  setShareAdzList: async ({ commit }, adzList) => {
    commit('setShareAdzList', adzList)
  },

  changeAdzAprroveStatus: async ({ commit }, adz, status) => {
    commit('changeAdzAprroveStatus', adz, status)
  },
}
