export default {
  setAccount: (state, account) => (state.account = account),
  setMembership: (state, membershipConfig) => (state.accountMembership = membershipConfig),
  setClients: (state, clients) => (state.clients = clients),
  setClient: (state, client) => (state.selectedClient = client),
  setCampaigns: (state, campaigns) => (state.campaigns = campaigns),
  setCampaign: (state, campaign) => (state.selectedCampaign = campaign),
  setAdz: (state, adz) => (state.selectedAdz = adz),
  setShareAdzList: (state, adzList) => (state.shareAdzList = adzList),
  changeAdzAprroveStatus: (state, adz, status) => {
    state.selectedAdz.isApproved = status
  },
}
