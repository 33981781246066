import Vue from 'vue'
import { isNil } from 'lodash'
import PlatformsDB from '@/firebase/platforms-db'

export default {
  getPlatforms: async ({ commit }) => {
    const platformsDbRef = new PlatformsDB()
    const platforms = await platformsDbRef.readAll()
    commit('setPlatforms', platforms)
  },

  enterLiveEditMode: ({ commit }, e) => {
    // console.log('Live editing', e, e.target, commit)

    const target = e.target

    target.setAttribute('contenteditable', 'plaintext-only')

    target.focus()

    return commit
  },

  /**
   * Global doAlert() toaster message using Sweetalert2
   * title
   * type
   */
  doAlert(context, { title, type }) {
    if (type === 'error') {
      Vue.swal({
        title,
        icon: type,
      })
      return
    }

    Vue.swal({
      title,
      icon: type,
      toast: true,
      position: 'top-end',
      timer: 1500,
      timerProgressBar: false,
      showConfirmButton: false,
    })
  },

  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  selectAdzToShare: async ({ commit }, value) => {
    commit('setadzIsSelected', value)
  },

  setCommentingAdz: async ({ commit }, value) => {
    commit('setCommentingAdz', value)
  },

  globToggleComments: async ({ commit }) => {
    commit('globToggleComments')
  },

  globTurnOffComments: async ({ commit }) => {
    commit('globTurnOffComments')
  },

  globToggleResolved: async ({ commit }) => {
    commit('globToggleResolved')
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return
    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  },
}
