var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footerbar"},[_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"left"},[(_vm.user)?_c('router-link',{attrs:{"to":"/clients"}},[_c('img',{staticClass:"showadz-icon",attrs:{"alt":"Showadz icon","src":require("@/assets/img/showadz-icon.svg")}})]):_c('img',{staticClass:"showadz-icon",attrs:{"alt":"Showadz icon","src":require("@/assets/img/showadz-icon.svg")}}),(_vm.user && _vm.selectedClient)?_c('img',{staticClass:"footer-arrow-right",attrs:{"alt":"Arrow right","src":require("@/assets/img/footer-arrow-right.svg")}}):_vm._e(),(_vm.user && _vm.selectedClient)?_c('router-link',{attrs:{"to":{
          name: 'CampaignsList',
          params: { clientId: _vm.selectedClient.id },
        }}},[_c('p',{class:{ active: _vm.$route.name == 'CampaignsList' }},[_vm._v(" "+_vm._s(_vm.selectedClient.name)+" ")])]):_vm._e(),(_vm.user && _vm.selectedClient && _vm.selectedCampaign)?_c('img',{staticClass:"footer-arrow-right",attrs:{"alt":"Arrow right","src":require("@/assets/img/footer-arrow-right.svg")}}):_vm._e(),(_vm.user && _vm.selectedClient && _vm.selectedCampaign)?_c('router-link',{attrs:{"to":{
          name: 'Campaign',
          params: {
            clientId: _vm.selectedClient.id,
            campaignId: _vm.selectedCampaign.id,
          },
        }}},[_c('p',{class:{ active: _vm.$route.name == 'Campaign' }},[_vm._v(" "+_vm._s(_vm.selectedCampaign.name)+" ")])]):_vm._e(),(_vm.user && _vm.selectedClient && _vm.selectedCampaign && _vm.selectedAdz)?_c('img',{staticClass:"footer-arrow-right",attrs:{"alt":"Arrow right","src":require("@/assets/img/footer-arrow-right.svg")}}):_vm._e(),(_vm.user && _vm.selectedClient && _vm.selectedCampaign && _vm.selectedAdz)?_c('router-link',{attrs:{"to":{
          name: 'CreateFacebookAds',
          params: {
            clientId: _vm.selectedClient.id,
            campaignId: _vm.selectedCampaign.id,
            adzId: _vm.selectedAdz.id,
          },
        }}},[_c('p',{class:{
            active: _vm.$route.name == 'CreateFacebookAds' || _vm.$route.name == 'Facebook',
          }},[_vm._v(" "+_vm._s(_vm.selectedAdz.name)+" ")])]):_vm._e(),(_vm.$route.name === 'CreateGoogle')?_c('img',{staticClass:"footer-arrow-right",attrs:{"alt":"Arrow right","src":require("@/assets/img/footer-arrow-right.svg")}}):_vm._e(),(_vm.$route.name === 'CreateGoogle')?_c('p',{staticClass:"active"},[_vm._v("Import Google ads")]):_vm._e(),(_vm.$route.name === 'google')?_c('img',{staticClass:"footer-arrow-right",attrs:{"alt":"Arrow right","src":require("@/assets/img/footer-arrow-right.svg")}}):_vm._e(),(_vm.$route.name === 'google')?_c('p',{staticClass:"active"},[_vm._v("Preview Google ads")]):_vm._e()],1),_c('div',{staticClass:"right"},[(
          _vm.$route.name === 'Facebook' ||
          _vm.$route.name === 'facebook-preview-all' ||
          _vm.$route.name === 'share-link' ||
          _vm.$route.name === 'share-link-google' ||
          _vm.$route.name === 'google' ||
          _vm.$route.name === 'Stories'
        )?_c('div',{staticClass:"resolved-wrap",class:{ show: _vm.commentsToggle }},[_c('p',{class:{ active: _vm.resolvedToggle }},[_vm._v("Show Resolved")]),_c('div',{staticClass:"resolved-toggle",class:{ active: _vm.resolvedToggle },on:{"click":_vm.toggleResolvedStatus}},[_c('img',{attrs:{"alt":"check","src":require("@/assets/img/check-icon-grey.svg")}})])]):_vm._e(),(
          _vm.$route.name === 'Facebook' || _vm.$route.name === 'facebook-preview-all' || _vm.$route.name === 'share-link' || _vm.$route.name === 'Stories' //||
          // $route.name === 'share-link-google' ||
          // $route.name === 'google'
        )?_c('div',{staticClass:"comments-wrap"},[_c('p',[_vm._v("Comments")]),_c('div',{staticClass:"comment-toggle",class:{ active: _vm.commentsToggle },on:{"click":_vm.toggleCommentStatus}},[_c('div',{staticClass:"round"})])]):_vm._e(),_c('a',{staticClass:"share-button",class:{ 'is-active zoomIn animated': _vm.adzIsSelected && _vm.shareAdzList && _vm.$route.name === 'Campaign' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getSharableLink.apply(null, arguments)}}},[_vm._m(0)]),_c('a',{staticClass:"share-button",class:{ 'is-active zoomIn animated': _vm.$route.name === 'Facebook' || _vm.$route.name === 'Stories' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getSharableLinkForCurrentAdz.apply(null, arguments)}}},[_vm._m(1)]),_c('a',{staticClass:"share-button",class:{ 'is-active zoomIn animated': _vm.$route.name === 'facebook-preview-all' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getSharableLinkForAllAdz.apply(null, arguments)}}},[_vm._m(2)]),_c('a',{staticClass:"share-button",class:{ 'is-active zoomIn animated': _vm.$route.name === 'google' },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getSharableLinkGoogle.apply(null, arguments)}}},[_vm._m(3)])])]),_c('ShareGeneratedLinkPopup',{attrs:{"show-custom-swal":_vm.showCustomSwal,"close-custom-swal":_vm.closeCustomSwal,"generated-link":_vm.generatedLink}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bt"},[_c('p',[_vm._v("Share")]),_c('img',{staticClass:"bt-img bt-img-share",attrs:{"alt":"Share","src":require("@/assets/img/share-buttom.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bt"},[_c('p',[_vm._v("Share")]),_c('img',{staticClass:"bt-img bt-img-share",attrs:{"alt":"Share","src":require("@/assets/img/share-buttom.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bt"},[_c('p',[_vm._v("Share")]),_c('img',{staticClass:"bt-img bt-img-share",attrs:{"alt":"Share","src":require("@/assets/img/share-buttom.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bt"},[_c('p',[_vm._v("Share")]),_c('img',{staticClass:"bt-img bt-img-share",attrs:{"alt":"Share","src":require("@/assets/img/share-buttom.svg")}})])}]

export { render, staticRenderFns }