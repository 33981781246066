<template>
  <div class="page-wrapper">
    <div class="left">
      <div class="loginWrap">
        <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />

        <h1 v-if="invite && invite.emailAlreadyExists" class="big-title">Create your password</h1>
        <h1 v-if="invite && !invite.emailAlreadyExists" class="big-title">Create your password</h1>

        <p v-if="userIsConnected && invite && invite.emailAlreadyExists">
          You are connected with a different account. Please login with the matching account to join.
        </p>
        <p v-if="userIsConnected && invite && !invite.emailAlreadyExists">
          You are already connected to Showadz. Please, logout if you want to create a new account.
        </p>

        <form v-if="!userIsConnected" action="">
          <input v-model="email" type="text" placeholder="Email" required readonly="" />
          <input v-model="password" type="password" placeholder="Password" required />

          <button type="submit" class="bt" :class="{ 'is-loading': isLoading }" @click.prevent="createUser()">
            <div class="bt-text">
              <p>Join</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
            <p class="loader">
              &nbsp;
              <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
                <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                  <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                  </path>
                </g>
              </svg>
              &nbsp;
            </p>
          </button>
        </form>

        <!-- <p class="or">OR</p> -->

        <!-- Loader -->
        <div v-show="user === undefined" data-test="loader">Authenticating...</div>

        <!-- Offline instruction -->
        <div v-show="!networkOnLine" data-test="offline-instruction">Please check your connection, login feature is not available offline.</div>

        <p v-if="loginError">{{ loginError }}</p>

        <!-- <section
          v-show="networkOnLine"
          id="firebaseui-auth-container"
        ></section> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import { auth } from '@/firebase/init'

import InvitesDB from '@/firebase/invites-db'
import AccountsDB from '@/firebase/accounts-db'

import { doAlert } from '@/misc/helpers'

export default {
  data: () => ({
    isLoading: false,
    loginError: null,
    email: '',
    password: '',
    userIsConnected: false,
    invite: null,
  }),
  head: function () {
    return {
      title: {
        inner: 'Login',
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc',
        },
      ],
    }
  },
  computed: {
    userExists() {
      return this.account && this.invite && this.userIsConnected && this.user.email === this.invite.email
    },
    ...mapState('authentication', ['user', 'activeInvite']),
    ...mapState('app', ['networkOnLine', 'appTitle']),
    ...mapState('users', ['account']),
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route)) {
          const invitesDBRef = new InvitesDB()
          this.invite = await invitesDBRef.read($route.params.inviteId)
          this.email = this.invite.email
          this.password = ''

          this.setActiveInvite(this.invite)
        }
      },
      immediate: true,
    },
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.userIsConnected = true
        } else {
          this.userIsConnected = false
        }
      },
      immediate: true,
    },
    userExists: {
      async handler(userExists) {
        if (userExists) {
          // console.log('User Exists and we can Link it instead of creating...')

          // console.log(this.account[0].id)

          try {
            const accountsDBRef = new AccountsDB()
            let newAccount = await accountsDBRef.read(this.account[0].id)

            // console.log({ newAccount }, this.user.id)

            const accountUsersRoles = newAccount.usersRoles
            accountUsersRoles[this.user.id] = {
              role: this.invite.role,
              clients: this.invite.clients,
            }

            // console.log({ accountUsersRoles })

            newAccount = { ...newAccount, usersRoles: accountUsersRoles }

            // console.log({ newAccount })

            newAccount = accountsDBRef.update(newAccount)

            doAlert({
              title: `User updated with success`,
              type: 'success',
            })

            this.isSaving = false
            this.isActive = false

            this.$router.push({ name: 'Clients' })
          } catch (error) {
            doAlert({ title: error.message, type: 'error' })
            this.isSaving = false
            return
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async createUser() {
      this.isLoading = true

      try {
        if (this.invite.emailAlreadyExists) {
          await auth.signInWithEmailAndPassword(this.email, this.password)
          this.$router.push({ name: 'confirminvite', params: { inviteId: this.invite.id } })
        } else {
          await auth.createUserWithEmailAndPassword(this.email, this.password)
        }
        // console.log({ signup })
        this.$router.push('/clients')
      } catch (error) {
        // doAlert({ title: error, type: 'error' })
        this.loginError = error
        this.isLoading = false
      }
    },
    ...mapActions('authentication', ['setActiveInvite']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/typography.scss';

.page-wrapper {
  display: flex;
}

.left {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  text-align: center;

  .logo {
    width: 250px;
    display: inline-block;
    margin-bottom: 50px;
  }

  .loginWrap {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    text-align: center;
  }

  h1 {
    margin-bottom: 30px;
  }

  input[type='text'] {
    margin-bottom: 10px;
  }

  input[type='password'] {
    margin-bottom: 10px;
  }

  .bt {
    width: 100%;

    &:hover {
      padding: 15px 25px 15px 25px;
    }
  }

  .or {
    margin: 15px 0;
    font-weight: 300;
    font-size: 14px;
  }
}
</style>
