<template>
  <div class="update-wrapper">
    <div class="update">
      <template v-if="refreshingApp">
        <div class="top"></div>
        <img alt="refresh" class="refresh-icon" src="@/assets/img/refresh-new.svg" />
        <p>Loading new version...</p>
      </template>

      <template v-else>
        <div class="top"></div>
        <img alt="close" class="close" src="@/assets/img/close.svg" @click="$emit('refresh')" />
        <img alt="refresh" class="refresh-icon" src="@/assets/img/refresh-new.svg" />
        <p>A new version of Showadz is available!</p>
        <span class="refresh-btn" @click="$emit('refresh')">Update now</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refreshingApp: Boolean,
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.update {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 380px;
  z-index: 1000000000000;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;

  .top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: linear-gradient(to right, #2bb0c5, #6bd8ba);
  }

  .close {
    position: absolute;
    top: 40px;
    right: 30px;
    cursor: pointer;
    width: 12px;
    height: auto;
  }

  p {
    font-size: 16px;
    color: #637082;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .refresh-icon {
    width: 25px;
    height: auto;
    display: inline-block;
    margin-bottom: 5px;
  }
}

.refresh-btn {
  padding: 10px 25px 10px 25px;
  border-radius: 3px;

  font-family: 'Open Sans', sans-serif;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  transition: all 0.4s ease;
  border: 1px solid $dark-grey;
}
</style>
