export default {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) => (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) => (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  setadzIsSelected: (state, value) => (state.adzIsSelected = value),
  setCommentingAdz: (state, value) => (state.commentingAdz = value),
  setPlatforms: (state, platforms) => (state.platforms = platforms),
  globToggleComments: (state) => (state.commentsToggle = !state.commentsToggle),
  globTurnOffComments: (state) => (state.commentsToggle = false),
  globToggleResolved: (state) => (state.resolvedToggle = !state.resolvedToggle),
}
