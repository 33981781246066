import Vue from 'vue'
// import axios from 'axios'

import VueSweetalert2 from 'vue-sweetalert2'
import VueClipboard from 'vue-clipboard2'

import App from './App.vue'
import router from './router'
import store from './store'

import '@/misc/register-service-worker'
// import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/firebase/authentication'
// import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false
// Vue.prototype.$http = axios

Vue.use(VueSweetalert2)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
