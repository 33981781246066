<template>
  <div id="app" class="root-app" :class="'page-' + $route.name">
    <!-- Nav Bar -->
    <NavBar v-if="user && user !== undefined" />

    <!-- Page Content -->
    <router-view />

    <!-- Footer -->
    <FooterBar />

    <!-- App Overlays -->
    <AdBlockerDetected v-if="adBlockerDetected" class="ad-blocker-detected-toastr"> </AdBlockerDetected>
    <NewContentAvailableToastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></NewContentAvailableToastr>
    <!-- <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal> -->
    <!-- Custom SWAL for Account Membership Guard -->
    <MembershipGuard
      :showCustomSwal="membershipGuard"
      :closeCustomSwal="
        () => {
          membershipGuard = false
        }
      "
    />
  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBar from '@/components/layouts/NavBar'
import FooterBar from '@/components/layouts/FooterBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import MembershipGuard from '@/components/MembershipGuard'
import AdBlockerDetected from '@/components/AdBlockerDetected'
// import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

import { detectAdBlock } from '@/misc/helpers'

export default {
  components: {
    NavBar,
    FooterBar,
    NewContentAvailableToastr,
    AdBlockerDetected,
    MembershipGuard,
  },

  data() {
    return {
      adBlockerDetected: false,
      membershipGuard: false,
    }
  },

  watch: {
    $route: {
      handler($route) {
        if (!isNil($route) && $route.query.membershipGuard) {
          this.membershipGuard = true
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account']),
  },

  beforeMount() {
    setTimeout(() => {
      this.adBlockerDetected = detectAdBlock()
      // Temp force false
      // this.adBlockerDetected = false
    }, 10000)
  },

  methods: mapActions('app', ['closeAddToHomeScreenModalForApple', 'serviceWorkerSkipWaiting']),
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/flickity-extends.scss';

* {
  box-sizing: border-box;
}

.page-default {
  .navbar,
  .footerbar {
    display: none;
  }
  padding: 0 !important;
}

.page-CreateAccount {
  .footerbar {
    display: none !important;
  }
}

body {
  margin: 0;
}

button {
  border: 0;
}

.root-app {
  min-height: 100vh;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: $light-grey;
  padding-top: 75px;
  padding-bottom: 100px;

  @media screen and (max-width: 570px) {
    padding-top: 10px;
    padding-bottom: 80px;
  }

  &.page-share-link {
    padding-top: 0;
  }

  :focus {
    outline: 0;
  }

  &.page-confirminvite {
    padding: 0;

    .footerbar {
      display: none;
    }
  }

  &.page-home {
    padding-top: 0;
  }

  &.page-google,
  &.page-share-link-google {
    background-color: #fff;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  .main-wrap {
    width: 100%;
    max-width: 1260px;
    min-width: 1260px;
    padding: 0 30px;
    margin: 0 auto;
    margin-top: 4vh;

    @media screen and (max-width: 570px) {
      min-width: 0;
    }
  }

  a {
    font-weight: 500;
    text-decoration: none;
  }

  img {
    display: block;
  }

  .root-app {
    color: #2c3e50;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .new-content-available-toastr {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    // .apple-add-to-home-screen-modal {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1000;
    //   width: fit-content;
    //   height: fit-content;
    //   margin: auto;
    //   display: none;
    // }
  }
}

.account-empty-image-letter {
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 0, 0, 0.08);
  line-height: 38px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.58);
  font-weight: 800;
}
</style>
