<template>
  <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#4628FF">
    <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
      <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
      <path d="M36 18c0-9.94-8.06-18-18-18">
        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
      </path>
    </g>
  </svg>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'

export default {
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        if (user === undefined) return
        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.push('/routeispath')
        }
        const redirectUrl = isNil(user) ? `/?redirectUrl=${this.$route.query.redirectUrl}` : this.$route.query.redirectUrl
        this.$router.push(redirectUrl)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.main-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
}
</style>
