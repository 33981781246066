<template>
  <footer class="footerbar">
    <div class="main-wrap">
      <div class="left">
        <router-link v-if="user" to="/clients">
          <img alt="Showadz icon" class="showadz-icon" src="@/assets/img/showadz-icon.svg" />
        </router-link>
        <img v-else alt="Showadz icon" class="showadz-icon" src="@/assets/img/showadz-icon.svg" />

        <img v-if="user && selectedClient" alt="Arrow right" class="footer-arrow-right" src="@/assets/img/footer-arrow-right.svg" />
        <router-link
          v-if="user && selectedClient"
          :to="{
            name: 'CampaignsList',
            params: { clientId: selectedClient.id },
          }"
        >
          <p :class="{ active: $route.name == 'CampaignsList' }">
            {{ selectedClient.name }}
          </p>
        </router-link>

        <img v-if="user && selectedClient && selectedCampaign" alt="Arrow right" class="footer-arrow-right" src="@/assets/img/footer-arrow-right.svg" />
        <router-link
          v-if="user && selectedClient && selectedCampaign"
          :to="{
            name: 'Campaign',
            params: {
              clientId: selectedClient.id,
              campaignId: selectedCampaign.id,
            },
          }"
        >
          <p :class="{ active: $route.name == 'Campaign' }">
            {{ selectedCampaign.name }}
          </p>
        </router-link>

        <img
          v-if="user && selectedClient && selectedCampaign && selectedAdz"
          alt="Arrow right"
          class="footer-arrow-right"
          src="@/assets/img/footer-arrow-right.svg"
        />
        <router-link
          v-if="user && selectedClient && selectedCampaign && selectedAdz"
          :to="{
            name: 'CreateFacebookAds',
            params: {
              clientId: selectedClient.id,
              campaignId: selectedCampaign.id,
              adzId: selectedAdz.id,
            },
          }"
        >
          <p
            :class="{
              active: $route.name == 'CreateFacebookAds' || $route.name == 'Facebook',
            }"
          >
            {{ selectedAdz.name }}
          </p>
        </router-link>

        <img v-if="$route.name === 'CreateGoogle'" alt="Arrow right" class="footer-arrow-right" src="@/assets/img/footer-arrow-right.svg" />
        <p v-if="$route.name === 'CreateGoogle'" class="active">Import Google ads</p>

        <img v-if="$route.name === 'google'" alt="Arrow right" class="footer-arrow-right" src="@/assets/img/footer-arrow-right.svg" />
        <p v-if="$route.name === 'google'" class="active">Preview Google ads</p>
      </div>

      <div class="right">
        <!-- Toggle Show Resolved -->
        <div
          v-if="
            $route.name === 'Facebook' ||
            $route.name === 'facebook-preview-all' ||
            $route.name === 'share-link' ||
            $route.name === 'share-link-google' ||
            $route.name === 'google' ||
            $route.name === 'Stories'
          "
          class="resolved-wrap"
          :class="{ show: commentsToggle }"
        >
          <p :class="{ active: resolvedToggle }">Show Resolved</p>
          <div class="resolved-toggle" :class="{ active: resolvedToggle }" @click="toggleResolvedStatus">
            <img alt="check" src="@/assets/img/check-icon-grey.svg" />
          </div>
        </div>

        <!-- Toggle Comments CTAs -->
        <div
          v-if="
            $route.name === 'Facebook' || $route.name === 'facebook-preview-all' || $route.name === 'share-link' || $route.name === 'Stories' //||
            // $route.name === 'share-link-google' ||
            // $route.name === 'google'
          "
          class="comments-wrap"
        >
          <p>Comments</p>
          <div class="comment-toggle" :class="{ active: commentsToggle }" @click="toggleCommentStatus">
            <div class="round"></div>
          </div>
        </div>

        <!-- Share actions -->
        <a
          href="#"
          :class="{ 'is-active zoomIn animated': adzIsSelected && shareAdzList && $route.name === 'Campaign' }"
          class="share-button"
          @click.prevent="getSharableLink"
        >
          <div class="bt">
            <p>Share</p>
            <img alt="Share" class="bt-img bt-img-share" src="@/assets/img/share-buttom.svg" />
          </div>
        </a>
        <a
          href="#"
          :class="{ 'is-active zoomIn animated': $route.name === 'Facebook' || $route.name === 'Stories' }"
          class="share-button"
          @click.prevent="getSharableLinkForCurrentAdz"
        >
          <div class="bt">
            <p>Share</p>
            <img alt="Share" class="bt-img bt-img-share" src="@/assets/img/share-buttom.svg" />
          </div>
        </a>
        <a
          href="#"
          :class="{ 'is-active zoomIn animated': $route.name === 'facebook-preview-all' }"
          class="share-button"
          @click.prevent="getSharableLinkForAllAdz"
        >
          <div class="bt">
            <p>Share</p>
            <img alt="Share" class="bt-img bt-img-share" src="@/assets/img/share-buttom.svg" />
          </div>
        </a>
        <a href="#" :class="{ 'is-active zoomIn animated': $route.name === 'google' }" class="share-button" @click.prevent="getSharableLinkGoogle">
          <div class="bt">
            <p>Share</p>
            <img alt="Share" class="bt-img bt-img-share" src="@/assets/img/share-buttom.svg" />
          </div>
        </a>
      </div>
    </div>

    <ShareGeneratedLinkPopup :show-custom-swal="showCustomSwal" :close-custom-swal="closeCustomSwal" :generated-link="generatedLink" />
  </footer>
</template>

<script>
// import firebase from 'firebase/app'
import { isNil } from 'lodash'

import { mapGetters, mapState, mapActions } from 'vuex'
import ShareLinksDB from '@/firebase/shareLinks-db'
import ShareLinksGoogleDB from '@/firebase/shareLinksGoogle-db'

import ShareGeneratedLinkPopup from '@/components/actions/ShareGeneratedLinkPopup'

// import { doAlert } from '@/misc/helpers'

export default {
  components: {
    ShareGeneratedLinkPopup,
  },
  data() {
    return {
      currentPage: this.$route.name,
      generatedLink: '',
      showCustomSwal: false,
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle', 'adzIsSelected', 'commentsToggle', 'resolvedToggle']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign', 'selectedAdz', 'shareAdzList']),
  },
  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route) && $route.name && !$route.name.includes('share-link')) {
          this.globTurnOffComments()
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getSharableLink() {
      // console.log(this.account[0].id, this.selectedClient.id, this.selectedCampaign.id, this.shareAdzList)

      const shareLinkContent = {
        userId: this.user.id,
        accountId: this.account[0].id,
        clientId: this.selectedClient.id,
        campaignId: this.selectedCampaign.id,
        adz: this.shareAdzList,
        preview: {
          clientName: this.selectedClient.name,
          clientImage: this.selectedClient.profileImg,
        },
      }

      // console.log(shareLinkContent)

      const shareLinksDbRef = new ShareLinksDB()
      const shareLink = await shareLinksDbRef.create(shareLinkContent)

      // doAlert({
      //   title: `Share this link to your client`,
      //   type: 'success'
      // })

      // const routeUrl = this.$router.resolve(`/share-link?slid=${shareLink.id}`)
      // console.log({routeUrl}, `https://${window.location.host}/share-link?slid=${shareLink.id}`)
      this.generatedLink = `https://${window.location.host}/share-link?slid=${shareLink.id}`
      this.showCustomSwal = true
      // window.open(routeUrl.href, '_blank')
    },
    async getSharableLinkForCurrentAdz() {
      const shareLinkContent = {
        userId: this.user.id,
        accountId: this.account[0].id,
        clientId: this.selectedClient.id,
        campaignId: this.selectedCampaign.id,
        adz: [this.$route.params.adzId],
        preview: {
          clientName: this.selectedClient.name,
          clientImage: this.selectedClient.profileImg,
        },
      }

      // console.log(shareLinkContent)

      const shareLinksDbRef = new ShareLinksDB()
      const shareLink = await shareLinksDbRef.create(shareLinkContent)

      // doAlert({
      //   title: `Share this link to your client`,
      //   type: 'success'
      // })

      // const routeUrl = this.$router.resolve(`/share-link?slid=${shareLink.id}`)
      // console.log({routeUrl}, `https://${window.location.host}/share-link?slid=${shareLink.id}`)
      this.generatedLink = `https://${window.location.host}/share-link?slid=${shareLink.id}`
      this.showCustomSwal = true
      // window.open(routeUrl.href, '_blank')
    },
    async getSharableLinkForAllAdz() {
      const shareLinkContent = {
        userId: this.user.id,
        accountId: this.account[0].id,
        clientId: this.selectedClient.id,
        campaignId: this.selectedCampaign.id,
        adz: null,
        preview: {
          clientName: this.selectedClient.name,
          clientImage: this.selectedClient.profileImg,
        },
      }

      // console.log(shareLinkContent)

      const shareLinksDbRef = new ShareLinksDB()
      const shareLink = await shareLinksDbRef.create(shareLinkContent)

      // doAlert({
      //   title: `Share this link to your client`,
      //   type: 'success'
      // })

      // const routeUrl = this.$router.resolve(`/share-link?slid=${shareLink.id}`)
      // console.log({routeUrl}, `https://${window.location.host}/share-link?slid=${shareLink.id}`)
      this.generatedLink = `https://${window.location.host}/share-link?slid=${shareLink.id}`
      this.showCustomSwal = true
      // window.open(routeUrl.href, '_blank')
    },
    async getSharableLinkGoogle() {
      const shareLinksGoogleDBRef = new ShareLinksGoogleDB()

      let shareLink = await shareLinksGoogleDBRef.readAll([
        ['userId', '==', this.user.id],
        ['accountId', '==', this.account[0].id],
        ['clientId', '==', this.selectedClient.id],
        ['campaignId', '==', this.selectedCampaign.id],
      ])

      if (!shareLink.length) {
        shareLink = await shareLinksGoogleDBRef.create({
          userId: this.user.id,
          accountId: this.account[0].id,
          clientId: this.selectedClient.id,
          campaignId: this.selectedCampaign.id,
        })
      } else {
        shareLink = shareLink[0]
      }

      // const routeUrl = this.$router.resolve({ name: 'share-link-google', query: { slid: shareLink.id } })
      // console.log({routeUrl}, `https://${window.location.host}/share-link?slid=${shareLink.id}`)
      this.generatedLink = `https://${window.location.host}/share-link-google?slid=${shareLink.id}`
      this.showCustomSwal = true
      // window.open(routeUrl.href, '_blank')
    },
    toggleCommentStatus() {
      this.globToggleComments()
    },
    toggleResolvedStatus() {
      this.globToggleResolved()
    },
    closeCustomSwal() {
      this.showCustomSwal = false
    },
    ...mapActions('app', ['globToggleComments', 'globTurnOffComments', 'globToggleResolved']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/button.scss';

.share-button {
  display: none;
  margin-left: 15px;

  &.is-active {
    display: block;
  }

  .bt {
    padding: 10px 15px 10px 15px;
    min-width: 0;
    display: flex;
    height: auto !important;

    &:hover {
      padding: 10px 35px 10px 15px;
    }

    p {
      opacity: 1;
    }
  }
}

.footerbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  padding: 10px 0;
  background-color: $dark-blue;

  .main-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    max-width: none;
    padding-left: 30px;
    padding-right: 30px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .resolved-wrap {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 15px;
    cursor: pointer;
    display: none;

    &.show {
      display: flex;
      display: none;
    }

    p {
      color: #fff;
      opacity: 1;
      margin-right: 5px;
      opacity: 0.3;

      &:hover {
        opacity: 0.3;
      }

      &.active {
        opacity: 1;
      }
    }

    .resolved-toggle {
      width: 20px;
      height: 20px;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      position: relative;

      img {
        width: 12px;
        position: absolute;
        top: 3px;
        left: 2px;
        display: none;
      }

      &.active {
        background-color: #fff;

        img {
          display: block;
        }
      }
    }
  }

  .comments-wrap {
    display: flex;
    align-items: center;
    justify-content: left;
    p {
      color: #fff;
      opacity: 1;
      margin-right: 5px;
    }
    .comment-toggle {
      position: relative;
      width: 45px;
      height: 25px;
      border: 2px solid #fff;
      border-radius: 25px;
      cursor: pointer;
      transition: all 0.2s ease;

      .round {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background: linear-gradient(to right, #2bb0c5, #6bd8ba);
        transition: all 0.6s ease;
      }

      &.active {
        background-color: #fff;
        .round {
          left: 25px;
        }
      }
    }
  }

  p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif !important;
    opacity: 0.3;
    transition: all 0.2s ease;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .showadz-icon {
    width: 40px;
    display: block;
  }

  .footer-arrow-right {
    width: 6px;
    opacity: 0.3;
    margin: 0 15px;
  }

  @media screen and (max-width: 570px) {
    .main-wrap {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
</style>
