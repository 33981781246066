import { isNil } from 'lodash'
import router from '@/router'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
// import PlatformsDB from '@/firebase/platforms-db'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase) ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser) : userFromFirebase

    commit('setUser', user)

    dispatch('users/getUserAccount', null, { root: true })

    // Platforms Seeder
    // const platformsDbRef = new PlatformsDB()
    // let platforms = await platformsDbRef.read('facebook')

    // if (isNil(platforms)) {
    //   await platformsDbRef.seed()
    //   platforms = await platformsDbRef.read('facebook')
    // }

    dispatch('app/getPlatforms', null, { root: true })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    commit('users/setAccount', null, { root: true })
    commit('users/setClients', null, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/')
    }
  },

  setActiveInvite: ({ commit }, invite) => {
    commit('setActiveInvite', invite)
  },
}
