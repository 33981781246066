export default {
  account: null,
  accountMembership: 'LEGACY',
  clients: null,
  selectedClient: null,
  campaigns: null,
  selectedCampaign: null,
  selectedAdz: null,
  shareAdzList: null,
}
