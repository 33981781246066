import Vue from 'vue'
import { firestorePlugin } from 'vuefire'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/performance'
// import 'firebase/analytics'
import 'firebase/functions'

Vue.use(firestorePlugin)

// 🔥The configuration below is not sensitive data. You can serenely add your config here
// STAGE
// const firebaseConfig = {
//   apiKey: 'AIzaSyAqT6eSfWIllWvpUrXN_akAraK2TkHmISc',
//   authDomain: 'showadz.firebaseapp.com',
//   databaseURL: 'https://showadz.firebaseio.com',
//   projectId: 'showadz',
//   storageBucket: 'showadz.appspot.com',
//   messagingSenderId: '107503949105',
//   appId: '1:107503949105:web:aefdaa8dba763057688c10',
//   measurementId: 'G-8L4GGBY4C2'
// }

// PROD
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBptzHXu5B1cMtffbfTgKgRtWc7CuHd3rg',
  authDomain: 'showadz-prod-6a02e.firebaseapp.com',
  databaseURL: 'https://showadz-prod-6a02e.firebaseio.com',
  projectId: 'showadz-prod-6a02e',
  storageBucket: 'showadz-prod-6a02e.appspot.com',
  messagingSenderId: '42979545510',
  appId: '1:42979545510:web:4925a6022c770147a1bf67',
  measurementId: 'G-9NFMC7WRFN',
}

firebase.initializeApp(firebaseConfig)

// Enable libs
const perf = firebase.performance()
// const analytics = firebase.analytics()

// 🔥firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const storageRef = firebase.storage().ref()
const functions = firebase.functions()

// Configs
auth.languageCode = 'en'

export { db, auth, storage, storageRef, functions, firebase, perf }
